.container {
    margin-top: 40px;
    padding: 0;
    position: absolute;
    width: 100%;
}

.navigation-enter {
    opacity: 0;
    transform: translateY(-100%);
}

.navigation-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 300ms;
}

.navigation-exit {
    opacity: 0;
    transform: translateY(-100%);
    transition: all 500ms;
}

@media screen and (min-width: 768px) {
    .container {
        padding: 0 1rem;
    }
}
