.main.main.main {
    padding: .5em;
    display: flex;
    flex-direction: column;
}

.header.header {
    display: flex;
}

.headerContent.headerContent.headerContent {
    margin: 0.56em 0;
    padding: 0 0 0 .25em;
    flex: 1 1 auto;
    align-self: center;
}

.headerButton.headerButton {
    margin: 0;
}
