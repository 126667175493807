.secondaryMenu.secondaryMenu.secondaryMenu {
    margin-top: 40px;
    z-index: 1000;
}

.loader.loader.loader:before {
    background: none;
}

.loader.loader.loader:after {
    height: 2em;
    width: 2em;
    margin: -1em 0 0 -1em;
}

.appMenu {
    display: none !important;
}

.mobileMenu {
}

@media screen and (min-width: 992px) {
    .appMenu {
        display: flex !important;
    }

    .mobileMenu {
        display: none !important;
    }
}
