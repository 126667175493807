.phoneLabel.phoneLabel.phoneLabel {
    width: 110px;
}

.input.input.input input {
    text-align: right;
}

.narrowOnMobile {
}

@media screen and (max-width: 767px) {
    .input.input.input.narrowOnMobile > input {
        border-right-color: rgba(34, 36, 38, 0.15) !important;
        border-top-right-radius: 0.28571429rem !important;
        border-bottom-right-radius: 0.28571429rem !important;
    }

    .input.input.input.narrowOnMobile > input:focus {
        border-right-color: #85B7D9 !important
    }

    .labelNarrowOnMobile.labelNarrowOnMobile.labelNarrowOnMobile {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .phoneLabel.phoneLabel.phoneLabel {
        display: none;
    }

    .phoneLabel.phoneLabel.phoneLabel.phoneLabel.phoneLabel:first-child + input {
        border-left-color: rgba(34, 36, 38, 0.15);
        border-top-left-radius: 0.28571429rem;
        border-bottom-left-radius: 0.28571429rem;
    }
}
