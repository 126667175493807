.divider.divider.divider {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.innerDivider.innerDivider.innerDivider {
    margin-top: 0;
    margin-left: -1rem;
    margin-right: -1rem;
}

.container.container.container {
    margin-top: 0;
}

.hideButton.hideButton.hideButton.hideButton {
    padding-top: 0;
}

.mobileButtons.mobileButtons.mobileButtons {
    width: 100%;
}

.computerButton.computerButton.computerButton {
    display: none;
}

@media screen and (min-width: 768px) {
    .hideButton.hideButton.hideButton.hideButton {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .mobileButtons.mobileButtons.mobileButtons {
        display: none;
    }

    .computerButton.computerButton.computerButton {
        display: block;
    }
}
