.main.main.main {
    padding: .5em .5em;
    display: flex;
    flex-direction: column;
}

.header.header.header {
    display: flex;
    padding-bottom: .5em;
}

.headerContent.headerContent {
    margin: 0;
    padding: .5em 1em .5em .5em;
    flex: 1 1 auto;
    align-self: center;
}

.headerButton.headerButton {
    margin: 0;
}

.buttons.buttons.buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: .5em;
}
