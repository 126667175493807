.table.table.table {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-top: 0;
}

.table.table.table:not(.single) {
    border: 0;
}

.table.table.table thead th {
    padding-top: .6em;
    padding-bottom: .5em;
}

.table:first-child {
    border-top: none;
}

.table thead th span {
    display: inline-block;
    height: 2em;
    padding: .25em;
}

.table tbody td span {
    padding: .25em 0;
}

.table:not(.single) tbody td span {
    margin-left: 1em;
}

.single.single.single {
    margin: 0;
}

.phoneLabel.phoneLabel.phoneLabel {
    width: 80px;
}

.tableHeaderChildren {
    display: none;
}

@media screen and (min-width: 768px) {
    .tableHeaderChildren {
        display: table-header-group;
    }
}
