.button.button.button {
    text-align: left;
    margin: 1px 0;
}

.text {
    display: none;
}

@media screen and (min-width: 768px) {
    .text {
        display: inline-block;
        vertical-align: middle;
    }
}

@media screen and (max-width: 767px) {
    i.icon.icon.icon.icon.icon.icon {
        margin-right: 0;
    }
}
