.camera {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background: #000;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.video {
    width: 100%;
    height: 100%;
}

.capture {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    font-size: 2rem;
    width: 5rem;
    margin-left: -2.5rem;
    background: transparent;
    border: none;

}

.cancel {
    position: absolute;
    bottom: 3.25rem;
    left: 1rem;
    font-size: 1rem;
    width: 2rem;
    background: transparent;
    border: none;
}
