.header {
    background: #f9fafb;
    border-bottom: 1px solid rgba(34, 36, 38, .1);
}

.header + div {
    margin-top: 1rem;
}

.disabledInput {
    opacity: 1 !important;
}

.buttons.buttons.buttons.buttons.buttons {
    display: none;
}

@media screen and (max-width: 767px) {
    .header [class*="five wide mobile"] {
        padding-left: .25rem !important;
        padding-right: .25rem !important;
    }

    .header [class*="five wide mobile"]:first-child {
        padding-left: 1rem !important;
    }

    .header [class*="six wide mobile"] {
        padding-left: .25rem !important;
    }

    .header [class*="sixteen wide mobile"] {
        padding-top: .5rem !important;
    }
}

@media screen and (min-width: 768px) {
    .header [class*="four wide tablet"] {
        padding-left: .25rem !important;
        padding-right: .25rem !important;
    }

    .header [class*="four wide tablet"]:first-child {
        padding-left: 1rem !important;
    }

    .header [class*="four wide tablet"]:last-child {
        padding-right: 1rem !important;
    }

    .buttons.buttons.buttons.buttons.buttons {
        display: block;
    }
}
