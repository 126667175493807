.mainHeader.mainHeader.mainHeader {
    padding: .5em .5em;
    display: flex;
}

.mainHeaderContent.mainHeaderContent {
    margin: 0;
    padding: .5em .5em;
    flex: 1 1;
}

.inlineLoader.inlineLoader.inlineLoader {
    padding: 0 2.5em;
}

.container.container.container {
    margin-top: 0;
    margin-bottom: 0;
}

.tabletEditCategories, .mobileEditCategories, .mainHeader {
    display: none !important;
}

@media screen and (max-width: 767px) {
    .mobileEditCategories {
        display: inline-block !important;
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .tabletEditCategories {
        display: block !important;
    }
}

@media screen and (min-width: 992px) {
    .mainHeader {
        display: flex !important;
    }
}

@media screen and (max-width: 991px) {
    .container > [class*="sixteen wide mobile"]:not(:first-child) {
        padding-top: 0 !important;
    }

    .inlineLoader.inlineLoader.inlineLoader {
        padding: 0 1.5em;
    }

    .inlineLoader.inlineLoader.inlineLoader:after {
        height: 2em;
        width: 2em;
        margin: -1.5em 0 0 -1em;
    }
}
