.dropdownContainer {
    display: flex;
}

.dropdown.dropdown.dropdown {
    flex: 1 1;
}

.appMenu.appMenu.appMenu {
    display: none;
}

@media screen and (min-width: 992px) {
    .dropdownContainer {
        display: none !important;
    }

    .appMenu.appMenu.appMenu {
        display: block;
        position: sticky;
        top: 54px;
        margin: 0;
    }

    .appMenu.appMenu.appMenu + .appMenu {
        top: 110px;
        margin-top: 14px;
    }
}
